@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.page__not-found{
     margin: 100px 40px;
     @include flex(center, center);
     flex-direction: column;
     gap: 30px;
     &-img{
          max-width: 600px;
          img{
               width: 100%;
          }
     }
     p {
          @include text(24px, 32px, 400, $blue);
          text-align: center;
          a {
               color: $borderBlue;
               transition: 0.2s ease;
               &:hover{
                    color: $btnBlue;
               }
          }
     }
}

@media screen and (max-width: 720px) {
     .page__not-found{
          margin: 80px 40px;
          p{
               font-size: 20px;
               line-height: 24px;
          }
     }
}