@import '../../resources/scss/mixins.scss';
@import '../../resources/scss/variables.scss';

.contacts{
     &__wrapper{
          @include flex(flex-start, center);
          gap: 40px;
     }
     &__part{
          &:last-child{
               width: 64%;
               aspect-ratio: 16/9;
               iframe{
                    width: 100%;
                    height: 100%;
               }
          }
          &:first-child{
               width: 36%;
               .section{
                    width: 100%;
               }
          }
     }
     &__info{
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-item{
               @include text(14px, 20px, 400, #555);
               i{
                    width: 28px;
                    text-align: left;
                    color: #333;
                    font-size: 18px;
               }
               a{
                    color: #555;
               }
          }
          &-link{
               text-decoration: underline !important;
               text-underline-offset: 4px;
               transition: 0.2s ease;
               &:hover{
                    cursor: pointer;
                    font-weight: 500;
               }
          }
     }
     &__title{
          @include text(24px, 32px, 500, $textGray);
          text-align: center;
     }
}

@media screen and (max-width: 840px) {
     .contacts{
          &__wrapper{
               flex-direction: column;
          }
          &__part{
               width: 100% !important;
          }
          &__info{
               &-item{
                    text-align: center;
               }
          }
     }
}