@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.not__found{
     @include flex(center, center);
     flex-direction: column;
     gap: 20px;
     img{
          width: 480px;
     }
     h6 {
          @include text(18px, 24px, 500, $blue);
          text-align: center;
     }
}