@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.error{
     &__container{
          margin: 100px 0;
          @include flex(center, center);
          flex-direction: column;
          img{
               width: 400px;
               margin-bottom: 24px;
          }
          h2{
               text-align: center;
               @include text(18px, 24px, 500, $blue);
          }
     }
}