@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.city{
     &__wrapper{
          margin: 100px 0;
     }
     &__btn{
          &-list{
               @include flex(flex-start, stracth);
               border-left: 1px solid $grayBorder;
          }
          &-item{
               padding: 16px 24px;
               @include text(18px, 24px, 400, $textGray);
               border-top: 1px solid $grayBorder;
               border-right: 1px solid $grayBorder;
               opacity: 0.6;
               &:hover{
                    cursor: pointer;
               }
               &-active{
                    position: relative;
                    opacity: 1;
                    border-top: 3px solid $yellow;
                    font-weight: 500;
                    &::before {
                         position: absolute;
                         content: '';
                         top: 100%;
                         left: 0;
                         z-index: 1;
                         width: 100%;
                         height: 1px;
                         background: white;
                    }
               }
          }
     }
     &__content{
          padding: 24px;
          border: 1px solid $grayBorder;
          @include text(18px, 24px, 400, $textGray);
          li {
               list-style-position: inside;
          }
          h1, h2, h3, h4, h5, h6 {
               font-size: 24px;
               line-height: 32px;
          }
     }
     &__blog{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 40px;
          padding: 24px;
          border: 1px solid $grayBorder;
          h2{
               @include text(18px, 24px, 400, $textGray);
          }
     }
}

@media screen and (max-width: 1000px) {
     .city{
          &__blog{
               grid-template-columns: repeat(2, 1fr);
          }
     }
}

@media screen and (max-width: 600px) {
     .city{
          &__blog{
               display: flex !important;
               flex-direction:column;
               gap: 40px;
          }
     }
}