@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";


.footer{
     padding: 20px 0;
     background: $footerBg;
     &__wrapper{
          display: grid;
          grid-template-columns: repeat(6, 1fr);
     }
     &__logo{
          width: 90%;
          margin-left: 5%;
     }
     &__part{
          padding: 0 10px;
          &:first-child{
               align-self: center;
          }
          &:not(:first-child){
               border-left: 2px solid $yellow;
          }
     }
     &__title{
          @include text(16px, 30px, 400, white);
          margin-bottom: 20px;
     }
     &__link{
          position: relative;
          display: block;
          @include text(12px, 20px, 400, #888);
          padding-left: 0;
          margin-bottom: 10px;
          transition: 0.3s ease;
          &::before{
               content: "";
               position: absolute;
               width: 0px;
               height: 1px;
               background: white;
               top: 50%;
               left: 0;
               transition: 0.3s ease;
          }
          &:hover{
               color: white;
               padding-left: 16px;
               &::before{
                    width: 10px;
               }
          }
     }
}

@media screen and (max-width: 1280px) {
     .footer{
          &__wrapper{
               grid-template-columns: repeat(3, 1fr);
               grid-gap: 40px 0;
          }
          &__part{
               &:first-child{
                    @include flex(center, center);
               }
          }
          &__logo{
               width: 160px;
          }
     }
}

@media screen and (max-width: 720px) {
     .footer{
          &__wrapper{
               grid-template-columns: repeat(2, 1fr);
          }
     }
}

@media screen and (max-width: 600px) {
     .footer{
          .container{
               max-width: 480px;
          }
          &__wrapper{
               grid-template-columns: 1fr;
          }
     }
}