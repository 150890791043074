@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.chairman__item{
     padding: 16px 24px;
     border: 1px solid $grayBorder;
     border-top: 5px solid $yellow;
     &-message{
          @include text(18px, 24px, 400, $textGray);
          margin-bottom: 8px;
     }
     &-header{
          @include flex(space-between, center);
          padding-bottom: 16px;
          border-bottom: 1px solid $grayBorder;
          &-part{
               &:first-child {
                    @include flex(flex-start, center);
                    gap: 40px;   
               }
               input[type="checkbox"] {
                    width: 24px;
                    height: 24px;
               }
          }
          &-item{
               @include flex(flex-start, center);
               gap: 12px;
               i{
                    font-size: 20px;
                    color: $textGray;
               }
               p{
                    @include text(16px, 20px, 400, $textGray);
               }
          }
     }
     &-title{
          @include text(20px, 24px, 600, $textGray);
          margin: 20px 0 10px;
     }
     &-file{
          @include text(18px, 24px, 500, $blue);
          list-style-position: inside;
          a {
               color: $blue !important;
               transition: 0.25s ease;
               &:hover{
                    color: $borderBlue !important;
               }
          }
     }
     &-textarea{
          width: 100%;
          padding: 8px 16px;
          border: 1px solid $grayBorder;
          resize: none;
     }
     &-edit{
          @include flex(flex-start, center);
          gap: 20px;
          margin: 20px 0 10px;
          button{
               width: 32px;
               height: 32px;
               background: $blue !important;
               color: white;
               border-radius: 4px;
               border: 0;
               transition: 0.3s ease;
               &:hover{
                    background: $btnBlue !important;
               }
          }
          h2 {
               margin: 0;
          }
     }
     &-more{
          @include text(14px, 14px, 400, $blue);
          margin-right: 12px;
          text-decoration: underline;
          transition: 0.2s ease;
          &:hover{
               cursor: pointer;
               color: $btnBlue;
          }
     }
}

@media screen and (max-width: 1000px) {
     .chairman__item{
          &-header{
               &-part{
                    &:first-child{
                         align-items: flex-start;
                         flex-direction: column;
                         gap: 8px;
                    }
               }
          }
     }
}