@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.section{
     &-1{
          position: relative;
          padding: 0;
          height: 600px;
          background-position: 0px 0px, 50% 50%;
          background-size: auto, cover;
          background-repeat: repeat, no-repeat;
     }
     &-2{
          position: relative;
          background-image: url(../../resources/img/blank_bg_both_side.png);
          background-position: 50% 50%;
          background-size: auto;
     }
     &-3{
          background-image: url(../../resources/img/main_page_logos_bg.jpg);
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
     }
     &-4{
          background-image: url(../../resources/img/blog_bg.png);
          background-position: 100% 100%;
          background-size: cover;
     }
     &-5{
          background-image: url(../../resources/img/contact_page_bg.jpg);
          background-position: 50% 50%;
          background-size: auto;
          background-repeat: repeat;
     }
}

.s1{
     &__wrapper{
          position: relative;
          height: 100%;
          @include flex(center, center);
          flex-direction: column;
     }
     &__img{
          width: 100px;
          margin-bottom: 20px;
     }
     &__title{
          &-additional{
               @include text(14px, 20px, 500, $dimYellow);
               text-transform: uppercase;
               margin-bottom: 20px;
          }
          &-first{
               @include text(48px, 44px, 700, $blue);
               margin-bottom: 30px;
               //text-shadow: 2px 2px 2px rgba(white, 0.4);
               -webkit-text-stroke: 0.3px white;
          }
          &-second{
               max-width: 680px;
               @include text(40px, 1.2, 700, $yellow);
               margin-bottom: 30px;
               text-align: center;
               //-webkit-text-stroke: 2px black;
          }
     }
     &__border{
          width: 100px;
          height: 5px;
          background: $yellow;
          margin-bottom: 20px;
     }
     &__btn{
          @include text(18px, 24px, 400, $yellow);
          text-transform: uppercase;
          padding: 15px 30px;
          background: $btnBlue;
          border-radius: 5px;
          border: 2px solid white;
          transition: 0.3s ease;
          &:hover{
               color: black;
               background: $yellow;
          }
     }
     &__link{
          position: absolute;
          bottom: 3%;
          &-img{
               width: 70px;
               height: 70px;
          }
     }
}

.s2{
     &__accordion{
          border: 1px solid $borderBlue;
          margin-bottom: 40px;
          &-toggle{
               @include flex();
               padding: 20px 40px 20px 20px;
               transition: 0.2s ease;
               i{
                    font-size: 14px;
               }
               &-title{
                    @include text(16px, 20px, 400, $darkGray);
               }
               &:hover{
                    cursor: pointer;
                    background: #f2f2f2;
               }
          }
          &-content{
               padding: 20px;
               display: grid;
               grid-gap: 10px;
               grid-template-rows: repeat(4, 1fr);
               grid-auto-flow: column;
               &-hide{
                    display: none;
               }
               &-link{
                    text-decoration: underline !important;
                    @include text(16px, 20px, 400, black);
               }
          }
     }
     &__map{
          position: relative;
          margin: 0 48px;
          &-img{
               width: 100%;
          } 
          &-city{
               position: absolute;
               width: 13px;
               height: 13px;
               border: 3px solid $btnBlue;
               background: $yellow;
               border-radius: 100%;
               &-name{
                    position: absolute;
                    bottom: calc(100% + 8px);
                    left: 50%;
                    transform: translateX(-50%);
                    @include text(20px, 20px, 600, $btnBlue);
               }
          }
     }
     &__link{
          position: absolute;
          bottom: 2%;
          left: 50%;
          transform: translateX(-50%);
          &-img{
               width: 70px;
               height: 70px;
          }
     }
}

#link-astana{
     left: 57%;
     top: 26%;
}
#link-almaty{
     left: 76%;
     top: 72%;
}
#link-aktobe{
     left: 24%;
     top: 26%;
}
#link-atyrau{
     left: 9%;
     top: 43%;
}
#link-karaganda{
     left: 61%;
     top: 35%;
}
#link-kokshetau{
     left: 52.5%;
     top: 10%;
}
#link-kostanay{
     left: 38%;
     top: 10%;
}
#link-pavlodar{
     left: 69%;
     top: 10%;
}
#link-petropavlovsk{
     left: 48%;
     top: 0%;
}
#link-turkestan{
     left: 51%;
     top: 76%;
}
#link-taraz{
     left: 61%;
     top: 79%;
}

.s3{
     &__slider{
          position: relative;
          overflow: hidden;
          background: $lightBlue;
          box-shadow: 4px 4px 40px 3px rgb(248, 252, 253);
          border-radius: 8px;
          &-container{
               overflow: hidden;
               margin: 75px 80px;
               z-index: 2;
               display: flex;
               width: calc(100% - 160px);
               transition: 0.5s ease;
          }
          &-list{
               display: flex;
               justify-content: flex-start;
               align-items: center;
               gap: 40px;
               height: 150px;
               transition: 0.5s ease;
               acronym{
                    height: 150px;
                    width: 200px;
               }
               img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
               }
          }
          &-btn{
               z-index: 3;
               width: 50px;
               height: 50px;
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               @include flex(center, center);
               border-radius: 100%;
               background: $btnBlue;
               i {
                    color: $yellow;
                    font-size: 36px;
               }
               &-left{
                    left: 15px;
               }
               &-right{
                    right: 15px;
               }
               &:hover{
                    cursor: pointer;
               }
          }
     }
     
}

.s4{
     &__blog{
          &-list{
               display: grid;
               grid-template-columns: repeat(3, 1fr);
               grid-gap: 60px;
          }
          &-item{
               border-top: 5px solid $yellow;
               border-radius: 10px;
               box-shadow: 0 17px 15px 0 rgba(0, 0, 0, 0.08);
               &-img{
                    aspect-ratio: 5/4;
                    img{
                         border-radius: 8px 8px 0 0;
                         object-fit: cover;
                         width: 100%;
                         height: 100%;
                    }
               }
               &-content{
                    padding: 30px;
               }
               &-title{
                    @include text(18px, 28px, 400, black);
                    margin-bottom: 10px;
               }
               &-date{
                    @include text(14px, 20px, 400, #333);
                    i{
                         margin-right: 8px;
                         color: #999;
                    }
               }
               &-text{
                    @include text(14px, 20px, 400, #333);
                    margin: 20px 0 30px;
               }
               &-link{
                    padding: 10px 20px;
                    border-radius: 5px;
                    background: $btnBlue;
                    @include text(12px, 22px, 400, white);
                    border: 1px solid $btnBlue;
                    transition: 0.25s ease;
                    &:hover{
                         background: white;
                         color: $btnBlue;
                    }
               }
          }
          &-link{
               margin-top: 30px;
               text-align: center;
               a{
                    @include text(15px, 21px, 400, black);
                    text-transform: uppercase;
                    text-decoration: underline;
               }
          }
     }
}

.s5{
     &__wrapper{
          @include flex(flex-start, center);
          gap: 40px;
     }
     &__part{
          &:first-child{
               width: 64%;
               aspect-ratio: 16/9;
               iframe{
                    width: 100%;
                    height: 100%;
               }
          }
          &:last-child{
               width: 36%;
               .section{
                    width: 100%;
               }
          }
     }
     &__info{
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-item{
               @include text(14px, 20px, 400, #555);
               i{
                    width: 24px;
                    color: #333;
                    font-size: 18px;
               }
               a{
                    color: #555;
               }
          }
     }
}

@media screen and (max-width: 1600px) {
     .s4{
          &__blog{
               &-item{
                    &-content{
                         padding: 20px;
                    }
               }
          }
     }
}

@media screen and (max-width: 1280px) {
     .section-1{
          height: 540px;
     }
     .s1{
          &__title{
               &-additional{
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 16px;
               }
               &-first{
                    font-size: 40px;
                    line-height: 40px;
                    margin-bottom: 20px;
               }
               &-second{
                    font-size: 36px;
               }
          }
          &__btn{
               font-size: 15px;
               line-height: 18px;
               padding: 12px 24px;
               border-radius: 4px;
          }
          &__link{
               &-img{
                    width: 56px;
                    height: 56px;
               }
          }
     }
     .s2{
          &__map{
               &-city{
                    width: 12px;
                    height: 12px;
                    &-name{
                         font-size: 16px;
                         line-height: 16px;
                    }
               }
          }
          &__link{
               &-img{
                    width: 56px;
                    height: 56px;
               }
          }
     }
     .s4{
          &__blog{
               &-list{
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr;
                    grid-auto-rows: 0;
               }
          }
     }
     .s5{
          &__info{
               &-item{
                    font-size: 13px;
                    i{
                         font-size: 16px;
                    }
               }
          }
     }
}

@media screen and (max-width: 1000px) {
     .section-1{
          height: 480px;
     }
     .section-2{
          padding: 5% 0 100px;
     }
}

@media screen and (max-width: 840px) {
     .s5{
          &__wrapper{
               flex-direction: column-reverse;
          }
          &__part{
               width: 100% !important;
          }
          &__info{
               &-item{
                    text-align: center;
               }
          }
     }
}

@media screen and (max-width: 720px) {
     .s1{
          &__title{
               &-first{
                    text-align: center;
               }
               &-second{
                    max-width: 540px;
                    font-size: 30px;
                    line-height: 36px;
               }
               &-additional{
                    text-align: center;
               }
          }
     }
     .s2{
          &__map{
               margin: 0;
               &-city{
                    width: 10px;
                    height: 10px;
                    border-width: 2.5px;
                    &-name{
                         bottom: calc(100% + 4px);
                         font-size: 14px;
                         line-height: 14px;
                    }
               }
          }
     }
}

@media screen and (max-width: 600px) {
     .section-4{
          background-image: none;
     }
     .s4{
          &__blog{
               &-list{
                    margin: 0 auto;
                    max-width: 480px;
                    display: flex;
                    flex-direction:column;
                    gap: 40px;
               }
          }
     }
}

@media screen and (max-width: 420px) {
     .s3{
          &__slider{
               &-container{
                    margin: 48px 64px;
                    width: calc(100% - 128px);
               }
               &-btn{
                    width: 40px;
                    height: 40px;
                    i{
                         font-size: 32px;
                    }
               }
          }
     }
}

@media screen and (max-width: 400px) {
     .section-1{
          height: 580px;
     }
}