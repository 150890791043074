@import "resources/scss/mixins.scss";
@import "resources/scss/variables.scss";
@import "./reset.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
     font-family: 'Montserrat', sans-serif;
}

.container{
     width: 1440px;
     margin: 0 auto;
     &-small{
          width: 1280px;
          margin: 0 auto;
     }
}

section{
     padding: 5% 0;
}

.section{
     background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./resources/img/section_img.jpg);
     background-position: 0px 0px, 50% 50%;
     background-size: auto, cover;
     background-repeat: repeat, no-repeat;
     padding: 180px 0;
     &__nav{
          margin: 0 40px;
          text-align: center;
          &-link{
               display: inline-block;
               @include text(16px, 22px, 400, white);
               text-transform: uppercase;
          }
          &-separator{
               @include text(16px, 22px, 400, white);
               margin: 0 12px;
          }
     }
     &__title{
          @include flex(center, center);
          flex-direction: column;
          gap: 20px;
          &-img{
               width: 150px;
          }
          &-txt{
               @include text(30px, 30px, 700, $btnBlue);
          }
          &-border{
               width: 100px;
               height: 5px;
               background: $yellow;
               margin-bottom: 40px;
          }
     }
}

@media screen and (max-width: 1600px) {
     .container{
          width: 1200px;
          &-small{
               width: 1200px;
          }
     }
}

@media screen and (max-width: 1280px) {
     .container{
          width: 960px;
          &-small{
               width: 960px;
          }
     }
     .section{
          padding: 140px 0;
          &__title{
               gap: 16px;
               &-img{
                    width: 120px;
               }
               &-txt{
                    font-size: 26px;
                    line-height: 26px;
               }
               &-border{
                    width: 80px;
                    height: 4px;
                    margin-bottom: 30px;
               }
          }
     }
}

@media screen and (max-width: 1000px) {
     .container{
          width: 90%;
          &-small{
               width: 100%;
          }
     }
}

@media screen and (max-width: 720px) {
     .section{
          padding: 120px 0;
     }
     section{
          padding: 60px 0;
     }
}

@media screen and (max-width: 600px) {
     .section{
          padding: 100px 0;
          &__title{
               gap: 12px;
               &-img{
                    width: 100px;
               }
               &-txt{
                    font-size: 24px;
                    line-height: 24px;
               }
               &-border{
                    width: 80px;
                    height: 4px;
                    margin-bottom: 30px;
               }
          }
     }
}