@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.article{
     padding: 40px 0;
     &__wrapper{
          display: grid;
          grid-template-columns: 7fr 3fr;
          grid-gap: 40px;
     }
     &__img{
          margin-bottom: 20px;
          img{
               width: 100%;
          }
     }
     &__part-title{
          @include text(24px, 32px, 500, $textGray);
          margin-bottom: 48px;
     }
     &__title{
          @include text(28px, 44px, 400, $textGray);
          margin-bottom: 10px;
     }
     &__date{
          @include text(14px, 20px, 400, $textGray);
          margin-bottom: 20px;
          i{
               color: $faviconGray;
               width: 25px;
               font-size: 16px;
          }
     }
     &__content{
          @include text(16px, 20px, 400, $textGray);
          a {
               text-decoration: underline;
               color: blue;
          }
     }
     &__item{
          @include flex(flex-start, flex-start);
          gap: 24px;
          margin-bottom: 16px;
          &-img{
               width: 80px;
               height: 80px;
               object-fit: cover;
          }
          &-content{
               &-title{
                    @include text(13px, 1.5em, 400, black);
                    text-transform: uppercase;
                    margin-bottom: 8px;
               }
               &-date{
                    @include text(13px, 16px, 400, black);
                    i{
                         color: $faviconGray;
                         font-size: 16px;
                         width: 20px;
                    }
               }
          }
     }
}

@media screen and (max-width: 1280px) {
     .article{
          .container{
               width: 90%;
          }
          &__part{
               &-title{
                    margin-bottom: 24px;
               }
          }
     }
}

@media screen and (max-width: 840px) {
     .article{
          &__wrapper{
               display: flex;
               flex-direction: column;
          }
          &__part{
               &:last-child{
                    padding-top: 40px;
                    border-top: 1px solid $grayBorder;
               }
          }
     }
}