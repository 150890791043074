@import "../../resources/scss/mixins.scss";
@import "../../resources/scss/variables.scss";

.blog {
     &__grid{
          margin-bottom: 100px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 40px;
     }
     &__search{
          position: relative;
          margin-top: 100px;
          margin-bottom: 40px;
          input{
               width: 100%;
               padding: 16px 24px;
               border: 1px solid $grayBorder;
          }
          svg{
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               right: 16px;
               font-size: 32px;
               color: $lightenBlue;
          }
     }
}

@media screen and (max-width: 1000px) {
     .blog{
          &__grid{
               grid-template-columns: repeat(2, 1fr);
          }
     }
}

@media screen and (max-width: 600px) {
     .blog{
          &__search{
               margin-top: 80px;
          }
          &__grid{
               margin: 0 auto 80px;
               max-width: 480px;
               display: flex !important;
               flex-direction:column;
               gap: 40px;
          }
     }
}